import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {Navigation, ReservationState, RouteParams, ThankYouMessageState} from '../types'

const getParts = (wixCodeApi: IWixAPI): string[] => {
  const {baseUrl, url, path} = wixCodeApi.location
  // fallback for weirder cases
  if (!baseUrl || !url.includes(baseUrl)) {
    return path.slice(1)
  }

  const strippedUrl = url.split('?')[0]
  const route = strippedUrl.replace(new RegExp(`${baseUrl}\\/?`), '').split('/')
  return route
}

const getRoute = (path: string[]): DETAILS_ROUTE => {
  return (path[2] || DETAILS_ROUTE.DETAILS) as DETAILS_ROUTE
}

export const getRouteParams = (wixCodeApi: IWixAPI): RouteParams => {
  const path = getParts(wixCodeApi)
  switch (getRoute(path)) {
    case DETAILS_ROUTE.ORDER:
      return {
        reservationId: path[3],
        reservationState: path[4] as ReservationState,
      }
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return {
        thankYouMessageState: path[3] as ThankYouMessageState,
      }
    default:
      return {}
  }
}

export const parseLocation = (wixCodeApi: IWixAPI): Navigation => {
  const path = getParts(wixCodeApi)
  return {
    sectionPath: path[0] || '',
    slug: path[1] || '',
    route: getRoute(path),
    ...getRouteParams(wixCodeApi),
  }
}
