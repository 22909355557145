import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {EVENTS_DETAILS_PAGE_ID, EVENTS_MEMBERS_WIDGET_ID, EVENTS_WIDGET_ID} from '@wix/wix-events-commons-statics'
import {createDetailsPageController} from './details-page/controllers'
import {createMembersPageController as createMembersAppController} from './members-page/controllers/members-page'
import {createWidgetController} from './widget/controllers'

const experiments = {}

export const initAppForPage = async () => {
  // const [exp] = await Promise.all([
  //   getExperimentsByScope(wixCodeApi, 'wix-events-ui'),
  // ])
  // experiments = {
  // ...exp
  // }
}

export const createControllers = (controllerConfigs: IWidgetControllerConfig[]) =>
  controllerConfigs.map(c => {
    if (c.type === EVENTS_WIDGET_ID) {
      return createWidgetController(c, experiments)
    } else if (c.type === EVENTS_MEMBERS_WIDGET_ID) {
      return createMembersAppController(c, experiments)
    } else if (c.type === EVENTS_DETAILS_PAGE_ID) {
      return createDetailsPageController(c, experiments)
    }
    return undefined
  })
