import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {RouteParams, State} from '../types'

export const getSectionPath = (state: State) => state.navigation.sectionPath

export const getSlug = (state: State) => state.navigation.slug

export const getRoute = (state: State) => state.navigation.route

export const getReservationState = (state: State) => state.navigation.reservationState

export const getThankYouMessageState = (state: State) => state.navigation.thankYouMessageState

export const inDetails = (state: State) => getRoute(state) === DETAILS_ROUTE.DETAILS

export const getUrl = (state: State, route: DETAILS_ROUTE, routeParams: RouteParams, query: string = '') => {
  const sectionPath = getSectionPath(state)
  if (!sectionPath) {
    return
  }

  const url = `/${sectionPath}/${getSlug(state)}`
  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return `${url}${query}`
    case DETAILS_ROUTE.ORDER:
      return `${url}/${DETAILS_ROUTE.ORDER}/${routeParams.reservationId}/${routeParams.reservationState}${query}`
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return `${url}/${DETAILS_ROUTE.THANK_YOU_MESSAGES}/${routeParams.thankYouMessageState}${query}`
    default:
      return `${url}/${route}${query}`
  }
}
