import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  encodeQueryParams,
  getExternalLink,
  isTicketed,
  isWithoutRegistration,
  UrlQueryParams,
  VisitorType,
  isRegistrationAutomaticallyClosed,
} from '@wix/wix-events-commons-statics'
import {Store} from 'redux'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {isEditor, isMobile, isPreview, isSite} from '../../commons/selectors/environment'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {setCurrentRoute} from '../../commons/utils/current-path-storage'
import {memberRsvpExists} from '../selectors/member-rsvp'
import {getUrl} from '../selectors/navigation'
import {getReservationId} from '../selectors/reservation'
import {shouldNavigateToForm} from '../selectors/rsvp'
import {renderSEOTags} from '../services/seo'
import {GetState, ReservationState, RouteParams, State, StoreExtraArgs, ThankYouMessageState} from '../types'
import {parseLocation} from '../utils/navigation'
import {isRestrictedTo} from '../selectors/event'
import {isMember} from '../selectors/current-member-details'
import {getOrder} from './placed-order'
import {getPolicies} from './policies'

export const NAVIGATE = 'NAVIGATE'

export const navigate = (route: DETAILS_ROUTE, routeParams: RouteParams = {}, query?: UrlQueryParams) => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const state = getState()
  const {route: validRoute, routeParams: validRouteParams} = validateRoute(state, route, routeParams)
  if (isEditor(state)) {
    await setCurrentRoute(validRoute)
  }

  dispatch({type: NAVIGATE, payload: {route: validRoute, ...validRouteParams}})

  if (isSite(state) || isPreview(state)) {
    const queryParams = query || wixCodeApi.location.query
    await dispatch(handleRoute(validRoute, queryParams))
    const encodedQuery = encodeQueryParams(queryParams)
    const nextUrl = getUrl(getState(), validRoute, validRouteParams, encodedQuery)

    return nextUrl && wixCodeApi.location.to(nextUrl)
  }
}

const handleRoute = (route: DETAILS_ROUTE, query: UrlQueryParams) => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return renderSEOTags(wixCodeApi, getState())
    case DETAILS_ROUTE.FORM:
    case DETAILS_ROUTE.TICKET_FORM:
      return dispatch(getPolicies({showAlert: false}))
    case DETAILS_ROUTE.ORDER:
      const {eventId, orderNumber, token} = query
      return dispatch(getOrder(eventId, orderNumber, token))
    default:
      return null
  }
}

export const validateRoute = (state: State, route: DETAILS_ROUTE, routeParams: RouteParams) => {
  if (!state.event) {
    return {route: DETAILS_ROUTE.NOT_FOUND}
  }
  if (isWithoutRegistration(state.event)) {
    return {route: DETAILS_ROUTE.LINK_EXPIRED}
  }
  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return {route: validateDetailsRoute(state), routeParams}
    case DETAILS_ROUTE.FORM:
      return {route: validateFormRoute(state), routeParams}
    case DETAILS_ROUTE.TICKET_FORM:
      return {route: validateTicketFormRoute(state), routeParams}
    case DETAILS_ROUTE.CHANGE_RSVP:
      return {route: validateChangeRsvpRoute(state), routeParams}
    case DETAILS_ROUTE.PICK_TICKETS:
      return {route: validateTicketsRoute(state), routeParams}
    case DETAILS_ROUTE.CHECKOUT:
      return {route: validateCheckoutRoute(state), routeParams}
    default:
      return {route, routeParams}
  }
}

const validateDetailsRoute = (state: State) =>
  isEditor(state) || isDetailsPageEnabled(state.siteSettings) ? DETAILS_ROUTE.DETAILS : DETAILS_ROUTE.FORM

const validateTicketFormRoute = (state: State) => {
  if (isSite(state) && !getReservationId(state)) {
    return isMobile(state) ? DETAILS_ROUTE.PICK_TICKETS : DETAILS_ROUTE.DETAILS
  }

  return DETAILS_ROUTE.TICKET_FORM
}

const validateCheckoutRoute = (state: State) => validateTicketFormRoute(state)

const validateFormRoute = (state: State) => {
  const isTicketedEvent = isTicketed(state.event)

  if (isSite(state) && isRestrictedTo(state, VisitorType.MEMBER) && !isMember(state)) {
    return DETAILS_ROUTE.DETAILS
  }

  if (isTicketedEvent) {
    return isEditor(state) ? DETAILS_ROUTE.TICKET_FORM : DETAILS_ROUTE.DETAILS
  }

  if (memberRsvpExists(state) && !isTicketedEvent && !shouldNavigateToForm(state)) {
    return DETAILS_ROUTE.CHANGE_RSVP
  }

  return DETAILS_ROUTE.FORM
}

const validateChangeRsvpRoute = (state: State) =>
  isTicketed(state.event) ? DETAILS_ROUTE.DETAILS : DETAILS_ROUTE.CHANGE_RSVP

const validateTicketsRoute = (state: State) => {
  if (isEditor(state) && !isMobile(state)) {
    // Probably opened desktop editor after playing in mobile preview
    return DETAILS_ROUTE.DETAILS
  }

  return DETAILS_ROUTE.PICK_TICKETS
}

export const navigateToDetails = () => navigate(DETAILS_ROUTE.DETAILS)

export const navigateToForm = () => navigate(DETAILS_ROUTE.FORM)

export const navigateToTicketForm = () => navigate(DETAILS_ROUTE.TICKET_FORM)

export const navigateToMobileCheckout = () => navigate(DETAILS_ROUTE.CHECKOUT)

export const navigateToTicketsPicker = () => navigate(DETAILS_ROUTE.PICK_TICKETS)

export const navigateToChangeRsvp = () => navigate(DETAILS_ROUTE.CHANGE_RSVP)

export const navigateToThankYouMessage = (thankYouMessageState: ThankYouMessageState) =>
  navigate(DETAILS_ROUTE.THANK_YOU_MESSAGES, {thankYouMessageState})

export const navigateToOrder = (
  reservationId: string,
  reservationState: ReservationState,
  queryParams: UrlQueryParams,
) => navigate(DETAILS_ROUTE.ORDER, {reservationState, reservationId}, queryParams)

export const navigateToMainPage = () => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const siteStructure = await wixCodeApi.site.getSiteStructure()
  const homePage = siteStructure.pages.find(page => page.isHomePage)
  wixCodeApi.location.to(homePage.url)
}

export const handleInitialNavigation = () => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const state = getState()
  const event = state.event

  if (event) {
    const externalLink = getExternalLink(event)
    const registrationAutomaticallyClosed = isRegistrationAutomaticallyClosed(event)
    const shouldRedirectIfExternal = isSite(state) && Boolean(externalLink) && !registrationAutomaticallyClosed

    if (shouldRedirectIfExternal) {
      wixCodeApi.location.to(externalLink)
    }
  }

  const {route, ...routeParams} = parseLocation(wixCodeApi)
  await dispatch(navigate(route, routeParams) as any)
}

export const addLocationListener = (wixCodeApi: IWixAPI, store: Store) => {
  wixCodeApi.location.onChange(async () => {
    const state = store.getState()
    if (isEditor(state)) {
      await setCurrentRoute(parseLocation(wixCodeApi).route)
    }
  })
}
